// React
import * as React from "react";
import { useRef, useLayoutEffect, useState } from "react";

// CSS
import "normalize.css";
import styled from "styled-components";

// GSAP
import gsap from "gsap";

// Meta Information
import SEO from "../components/reusable/Seo";
import { Helmet } from "react-helmet";

// Components: HTML Structure
import Layout from "../components/structure/Layout";
import MainContent from "../components/structure/MainContent";
import Branding from "../components/structure/Branding";
import Header from "../components/structure/Header";

// Gatsby
import { Link } from "gatsby";
import { ModalRoutingContext } from "gatsby-plugin-modal-routing-3";

import {
  technoOrange,
  glitchStylesVideo,
} from "../components/styles/global.module.css";

import {
  complianceStyles,
  termStyles,
  videoStyles,
  closeStyles,
  term1Styles,
  term2Styles,
  term3Styles,
} from "../components/styles/compliance.module.css";

const Container = styled.div`
  display: grid;
  grid-template-areas: "vision";
`;

const startOverLink = {
  gridArea: "3/1/4/3",
  alignSelf: "start",
  textDecoration: "none",
  margin: "3em 0 0 0",
  display: "block",
};

const startOverText = {
  gridArea: "3/1/4/3",
  alignSelf: "start",
  textDecoration: "none",
};

function CompliancePage() {
  const [activeLegalTab, setActiveLegalTab] = useState("cookie-policy");
  const tlCompliance = useRef();
  tlCompliance.current = gsap.timeline();
  const tlBranding = useRef();
  tlBranding.current = gsap.timeline();

  function getStandAloneTimelines(tl, name) {
    switch (name) {
      case "tl-branding":
        tlBranding.current.add(tl, name);
        break;
    }
  }

  useLayoutEffect(() => {
    tlCompliance.current
      .to(`#wrapper`, {
        duration: 0.1,
        backgroundColor: "#1A1A1A",
        overflow: "hidden",
        gridArea: "2/1/2/2",
      })
      .from(
        `#compliance .glitch-video`,
        {
          duration: 0.6,
          display: "block",
        },
        "-=0.1"
      )
      .to(`#compliance .glitch-video`, {
        duration: 0.1,
        display: "none",
      })
      .to(`#compliance dl`, {
        duration: 0.1,
        backgroundColor: "#1A1A1A",
        overflow: "hidden",
      })
      .to(".active-l-tab", { duration: 0.1, opacity: 1, zIndex: 1 })
      .to(".inactive-l-tab", { duration: 0.1, opacity: 0, zIndex: 0 })
      .to("#compliance dt", {
        duration: 0.1,
        borderBottom: "3px solid #1A1A1A",
        backgroundColor: "transparent",
      })
      .to(`#cookie-policy`, {
        duration: 0.1,
        borderBottom: "3px solid #fc4d0d",
        backgroundColor: "#303030",
      });

    return function cleanupListener() {
      try {
        tlBranding.current.kill();
        tlCompliance.current.kill();
      } catch (e) {
        // Code to run if an exception (error) occurs
      }
    };
  }, []);

  useLayoutEffect(() => {
    if (activeLegalTab) {
      gsap.to(".active-l-tab", { duration: 0.1, opacity: 1, zIndex: 1 });
      gsap.to(".inactive-l-tab", { duration: 0.1, opacity: 0, zIndex: 0 });
      gsap.to("#compliance dt", {
        duration: 0.1,
        borderBottom: "3px solid #1A1A1A",
        backgroundColor: "transparent",
      });
      gsap.to(`#${activeLegalTab}`, {
        duration: 0.1,
        borderBottom: "3px solid #fc4d0d",
        backgroundColor: "#303030",
      });
    }
  }, [activeLegalTab]);
  return (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) => (
        <div>
          {modal ? (
            <React.Fragment>
              <SEO title="Legal Compliance" article={true} />
              <Helmet>
                <title>Legal Compliance .:. Vision</title>
              </Helmet>
              <section id="compliance" className={complianceStyles}>
                <Link className={`${technoOrange} ${closeStyles}`} to={closeTo}>
                  Close
                </Link>
                <div id="wrapper">
                  <video
                    className={`glitch-video ${glitchStylesVideo} ${videoStyles}`}
                    src="/glitch.mp4"
                    autoPlay
                    muted
                    playsInline
                    loop
                  ></video>
                  <dl>
                    <dt
                      id="cookie-policy"
                      className={`${termStyles} ${technoOrange} ${term1Styles}`}
                      onClick={() => setActiveLegalTab("cookie-policy")}
                    >
                      Cookies Policy
                    </dt>
                    <dd
                      className={
                        activeLegalTab === "cookie-policy"
                          ? "active-l-tab"
                          : "inactive-l-tab"
                      }
                    >
                      <small>
                        A cookie is a small data file that a website server
                        sends to a search engine, which is then stored on a
                        user's computer. When a user revisits the same webpage,
                        the search engine sends this information back to the
                        webpage's server. Cookies are commonly used to identify
                        users, store user settings, etc., which simplifies the
                        use of websites.
                      </small>
                      <small>
                        Cookies themselves do not pose a threat to computers.
                        However, cookies are important in connection with the
                        issue of privacy, because the website uses cookies to
                        store all the information it learns about the visitor,
                        so it can gradually find out the interests of a
                        particular user. However, cookies can in no case be used
                        to reveal the identity of the visitor.
                      </small>
                      <small>
                        The information obtained from cookies can be used for
                        targeted advertising, statistical evaluation of visitor
                        behavior and the like. However, this information can
                        also be obtained without cookies and therefore its use
                        should not be considered particularly dangerous.
                      </small>
                      <h4>WHERE DO WE USE COOKIES?</h4>
                      <small>At kloveandsitch.agency.</small>
                      <h4>WHY DO WE USE COOKIES?</h4>
                      <small>
                        In summary, cookies are used to optimally set up the
                        information provided through all websites, links and
                        other parts of the website. Cookies do not slow down web
                        browsing.
                      </small>
                      <h4>TECHNICAL COOKIES</h4>
                      <small>
                        If your browser does not use cookies, the website will
                        not work properly. For example, the page will not
                        remember the data you entered and you would have to
                        enter it again and again.
                      </small>
                      <h4>THIRD PARTY COOKIES</h4>
                      <small>
                        We use partners to provide certain services. Our
                        partners also use cookies. These cookies are managed by
                        third parties and we do not have access to read or write
                        this data. Such third-party cookies are usually from
                        systems that provide advertising or analyze traffic. We
                        use anonymous identifiers and third-party cookies on our
                        website to target advertising content.
                      </small>
                      <h4>ANALYTICAL COOKIES</h4>
                      <small>
                        Analytical cookies help us to constantly improve our
                        website by tracking statistics, such as total website
                        traffic or number of clicks on a particular button, etc.
                        We use Google Analytics, Google AD Sense, Google Tag
                        manager, Hotjar as analytical tools.
                      </small>
                      <h4>MARKETING COOKIES</h4>
                      <small>
                        Cookies cannot send you any emails. Their job is to
                        display the website according to your preferences and
                        ensure that you are not burdened with something that
                        does not interest you. We use tools from Facebook,
                        Google.
                      </small>
                      <h4>CONSENT TO THE USE OF COOKIES</h4>
                      <small>
                        By using the Klove &amp; Sitch website, you confirm that
                        you agree to the use of cookies. You can restrict the
                        use of cookies or block them in the web browser
                        settings.
                      </small>
                      <h4>COOKIE MANAGEMENT</h4>
                      <small>
                        Cookies are enabled by your browser and you can manage
                        them yourself. There are several options:
                      </small>
                      <ul>
                        <li>
                          You can block (= disable) cookies. Doing so may cause
                          some websites to not display properly and some
                          features may not function as intended.
                        </li>
                        <li>
                          You can manage or delete cookies, but in this case
                          some parts of the website may not work properly.
                        </li>
                      </ul>
                      <h4>
                        HOW TO CHECK COOKIE SETTINGS IN DIFFERENT BROWSERS?
                      </h4>
                      Here's how to set up a specific browser:
                      <ul>
                        <li>Google Chrome</li>
                        <li>Firefox</li>
                        <li>Internet Explorer and Edge</li>
                        <li>Safari</li>
                      </ul>
                      Useful links:
                      <ul>
                        <li>More information about Google cookies, YouTube</li>
                        <li>More information about LinkedIn cookies</li>
                        <li>More information about Facebook cookies</li>
                      </ul>
                      <h4>
                        THE CONDITIONS FOR STORING AND PROCESSING COOKIES ARE AS
                        FOLLOWS:
                      </h4>
                      <ul>
                        <li>
                          The user always has the opportunity to express his
                          consent or refuse to store cookies by setting his web
                          browser - by allowing or, conversely, refusing to
                          store cookies.
                        </li>
                        <li>
                          In case of consent (cookie permission), the following
                          may be stored on the user's electronic device:
                          <ul>
                            <ol>
                              <li>
                                temporary cookies, which are automatically
                                deleted when the user's internet browser is
                                switched off,
                              </li>
                              <li>
                                long-term cookies, which remain in the user's
                                internet browser even after the device with
                                which the user accesses the kloveandsitch.agency
                                website is switched off (these cookies can be
                                deleted by the user at any time and will be
                                deleted automatically after a few weeks or
                                months).
                              </li>
                            </ol>
                          </ul>
                        </li>
                        <li>
                          With its consent, the user also allows the operator to
                          use cookies for its own registration or statistical
                          purposes, and for the purposes of targeted advertising
                          of partners with whom it has concluded special
                          agreements on displaying advertising on websites. The
                          user's consent lasts as long as the settings of his
                          internet browser are maintained. By changing the
                          user's internet browser settings or prohibiting the
                          use of cookies, the user's consent expires.
                        </li>
                        <li>
                          The Operator is not responsible for the storage of
                          cookies on the websites of third parties or the use of
                          cookies stored on the user's terminal equipment by
                          third parties.
                        </li>
                        <li>
                          In case of refusal of consent (rejection of cookies in
                          the web browser) the operator cannot guarantee the
                          full functionality of the kloveandsitch.agency
                          website.
                        </li>
                      </ul>
                      <br />
                      <small>Cookie Policy last update:</small>{" "}
                      <time dateTime="2021-12-31">December 31, 2021.</time>
                    </dd>
                    <dt
                      id="privacy-policy"
                      className={`${termStyles} ${technoOrange} ${term2Styles}`}
                      onClick={() => setActiveLegalTab("privacy-policy")}
                    >
                      Privacy Policy
                    </dt>
                    <dd
                      className={
                        activeLegalTab === "privacy-policy"
                          ? "active-l-tab"
                          : "inactive-l-tab"
                      }
                    >
                      <small>
                        The company Klove &amp; Sitch Agency Limited,
                        (hereinafter referred to as the “operator”) guarantees
                        the security and protection of entrusted personal data
                        in full compliance with European Parliament and Council
                        Regulation no. (EU) 2016/679 on the protection of
                        individuals with regard to the processing of personal
                        data and on the free movement of such data ("GDPR") and
                        Act no. 18/2018 Coll. On the protection of personal data
                        and on the amendment of certain laws as amended.
                      </small>
                      <h4>PRIVACY SECURITY</h4>
                      <small>
                        The kloveandsitch.agency website uses an encrypted SSL
                        connection for any user connection and transmission of
                        any data, which prevents third parties from accessing
                        the transferred data during its transmission on the
                        Internet and the modification of such data by third
                        parties. The operator's databases containing personal
                        data are protected by encryption and non-public access
                        data in accordance with the latest technical standards.
                      </small>
                      <h4>USER CONTACT ON REQUEST</h4>
                      <small>
                        When visiting and continuing to use the
                        kloveandsitch.agency website, the user may contact the
                        operator and provide him with his personal data for the
                        purpose of contacting him.
                      </small>
                      <small>
                        The provision of personal data is voluntary. In this
                        case, the legal basis for the processing of the data
                        provided by the user is the legitimate interest of the
                        controller to contact the user and provide any answers
                        or advice.
                      </small>
                      <small>
                        The personal data that the user can provide to the
                        operator are:
                      </small>
                      <ol>
                        <li>
                          name and surname{" "}
                          <span className={technoOrange}>[required]</span>
                        </li>
                        <li>
                          e-mail address{" "}
                          <span className={technoOrange}>[required]</span>
                        </li>
                        <li>company (workplace / employer) of the user.</li>
                      </ol>
                      <h4>PURPOSE</h4>
                      <small>
                        The purpose of processing the user's personal data is to
                        contact the user.
                      </small>
                      <h4>DURATION OF PROCESSING</h4>
                      <small>
                        Personal data will be processed only for the necessary
                        time during communication with the user.
                      </small>
                      <h4>CORRECTNESS</h4>
                      <small>
                        By voluntarily providing personal data, the user
                        declares that the provided data is correct, true and
                        current.
                      </small>
                      <h4>PERFORMANCE OF THE CONTRACT</h4>
                      <small>
                        We use your personal data for the preparation,
                        conclusion and performance of the contract for the time
                        necessary to process the build. After this period, we
                        continue to store the data on the basis of a legitimate
                        interest in order to protect legal claims and internal
                        records and control, for the duration of the limitation
                        period (3 years) and one year after its expiration with
                        regard to claims asserted at the end of the limitation
                        period. In the event of legal, administrative or other
                        proceedings, we process your personal data to the extent
                        necessary for the duration of such proceedings and the
                        remaining part of the limitation period after its
                        termination. Our legitimate interests in such processing
                        are the protection of legal claims and the control of
                        the proper provision of services. For the purposes of
                        fulfilling legal obligations, the Company processes
                        personal data for a period of 10 years. Personal data
                        processed for the purpose of performance of the contract
                        are processed to the extent that they were provided to
                        the operator for the purpose of performance of the
                        contract (ie in particular name, surname, address of
                        permanent residence, date of birth).
                      </small>
                      <h4>SOUND AND AUDIOVISUAL RECORDS</h4>
                      <small>
                        In order to publish audio recordings, audiovisual
                        recordings and video recordings from our training
                        sessions, we process personal data about you in the
                        scope of the recorded voice and your form only with your
                        consent. Such consent is required from you separately
                        when registering for the training, or before the start
                        of the training, and we keep such personal data for 3
                        years, or less if you revoke this consent during the
                        three years or modify some of your rights below. The
                        purpose of such processing is the presentation and
                        marketing of our company.
                      </small>
                      <h4>INTERESTED IN WORKING IN OUR COMPANY</h4>
                      <small>
                        Through our website you have the opportunity to send us
                        your CV, on the basis of which you can be selected and
                        employed in our company. We also need your consent in
                        order for us to process your personal data contained in
                        your CV. Unfortunately, without such consent, it will
                        not be possible for us to review the CV you have
                        submitted. In connection with the CV you have sent, we
                        process personal data about you to the extent of the
                        data you provide in this CV, and in particular: first
                        name, last name, tel. no. and email address. We store
                        personal data for the purpose of evaluating CVs for a
                        period of 1 year.
                      </small>
                      <h4>NEWSLETTER</h4>
                      <small>
                        The user - the affected person during his visit and
                        further use of the kloveandsitch.agency website may
                        provide the operator with his personal data for the
                        purpose of sending messages containing direct marketing
                        content - newsletter. The user's personal data may be
                        provided upon voluntary registration to receive the
                        newsletter using an e-mail address from which his or her
                        identity can be ascertained, or upon voluntary
                        completion of the contact form associated with the
                        selected content of the website. The operator may also
                        send newsletters to the user on the basis of his
                        legitimate interest, provided that the conditions set
                        out below are met.
                      </small>
                      <small>
                        The provision of personal data on the basis of consent
                        is voluntary. A necessary condition for processing
                        personal data for the purpose of sending a newsletter to
                        a user who is not the operator's customer is to give the
                        user's consent to the processing of personal data to the
                        necessary extent and sending marketing messages, which
                        consent is the only legal basis for processing data
                        provided by such user.
                      </small>
                      <small>
                        If the person concerned is a customer of the operator,
                        we send direct marketing messages regarding services
                        that are similar to the services that such a customer
                        has already ordered from us, while the legal basis for
                        such processing of personal data of existing customers
                        is our legitimate interest, as reasonably we assume that
                        our offers are interesting and relevant for these
                        customers. Of course, the customer has the option to
                        refuse to send such messages free of charge, either via
                        the designated link provided in the message, or by
                        contacting the operator via e-mail
                        letsbuild@kloveandsitch.com.
                      </small>
                      <h4>DURATION OF CONSENT</h4>
                      <small>
                        According to the above, the user's consent takes effect
                        at the time of voluntary consent by ticking the
                        appropriate box in the online form and lasts for two
                        years or until the user's consent is revoked. The
                        operator has the right to process the provided data for
                        the duration of the consent.
                      </small>
                      <h4>DURATION OF LEGAL INTEREST</h4>
                      <small>
                        The processing of personal data for marketing purposes,
                        the legal basis of which is the legitimate interest of
                        the operator, lasts for a period of two years from the
                        date of the last order to the customer or until the
                        refusal to send such messages, respectively. the period
                        of objection to the processing of personal data for this
                        purpose.
                      </small>
                      <h4>PURPOSE</h4>
                      <small>
                        The purpose of processing the user's personal data is to
                        enable the collection of the requested newsletter
                        (information leaflet) or sending a newsletter with
                        offers of similar goods and services to those already
                        ordered by the user, by sending such an operator
                        newsletter to contact addresses provided by the user,
                        especially in electronic form.
                      </small>
                      <h4>SCOPE OF PERSONAL DATA</h4>
                      <small>
                        If we process your personal data for the purpose of
                        sending newsletters, we process this in the range of:
                        name, surname and e-mail address.
                      </small>
                      <h4>CORRECTNESS</h4>
                      <small>
                        The user is obliged to report to the operator any change
                        in his personal data. The operator may request the user
                        to check or update the personal data provided by him in
                        order to ensure their accuracy.
                      </small>
                      <h4>DATA TRANSMISSION AND AUTOMATED DECISION - MAKING</h4>
                      <small>
                        The user's personal data will not be published or
                        provided to third parties, nor will the operator make
                        decisions that are important for the user in an
                        automated manner. In connection with the sending of
                        newsletters, your personal data may be transferred to a
                        country outside the user’s country, specifically to the
                        United States of America (USA). This is in connection
                        with software tools used to send our newsletters. In the
                        event that personal data is made available to persons
                        outside the user’s country, the operator ensures that
                        the recipients are certified by the so-called
                        "PrivacyShield" certificate.
                      </small>
                      <h4>
                        INSTRUCTIONS ON THE RIGHTS OF THE PERSON CONCERNED
                      </h4>
                      <small>
                        If you have provided us with your personal data, you are
                        entering the position of so-called the person concerned.
                        As an affected person, you can exercise the following
                        rights in particular with our company:
                      </small>
                      <ul>
                        <li>
                          the right to access their data. At the request of the
                          data subject, the Company will issue a confirmation as
                          to whether the data subject's personal data concerning
                          him or her are being processed. If the Company
                          processes this data, it will issue a copy of this
                          personal data of the data subject upon request. In
                          addition to the above, the data subject will be
                          provided with information on in particular the purpose
                          of the processing, the category of personal data
                          processed, the identification of the recipient or
                          categories of recipients to whom the personal data
                          were provided, the retention period, personal data,
                          information on the existence and automated individual
                          decision-making, Issuing the first copy according to
                          the previous sentence is free of charge. For any
                          additional copies requested by the person concerned,
                          the Company will charge a fee corresponding to the
                          administrative costs incurred in making the copy. If a
                          person requests information by electronic means, it
                          will be provided to him in a commonly used electronic
                          form, by e-mail, unless he requests otherwise.
                        </li>
                        <li>
                          the right to correct personal data if the Company
                          records incorrect personal data about the person
                          concerned. At the same time, the data subject has the
                          right to complete incomplete personal data. The
                          company will correct or supplement personal data
                          without undue delay after the person concerned
                          requests it.
                        </li>
                        <li>
                          the right to have personal data concerning the data
                          subject erased, provided that:
                          <ol>
                            <li>
                              the personal data are no longer necessary for the
                              purposes for which they were obtained or otherwise
                              processed;
                            </li>
                            <li>
                              the person concerned withdraws the consent on the
                              basis of which the processing takes place,
                            </li>
                            <li>
                              the data subject objects to the processing of
                              personal data pursuant to para. 6,
                            </li>
                            <li>
                              personal data have been processed illegally,
                            </li>
                            <li>
                              the reason for deletion is the fulfillment of an
                              obligation under the Act, special regulation or
                              international agreement by which the applicable
                              law is bound, or
                            </li>
                            <li>
                              personal data were obtained in connection with the
                              offer of information society services to a person
                              under 16 years.
                            </li>
                          </ol>
                        </li>
                      </ul>
                      <small>
                        The data subject will not have the right to have
                        personal data erased provided that their processing is
                        necessary:
                      </small>
                      <ol>
                        <li>
                          to exercise the right to freedom of expression and
                          information;
                        </li>
                        <li>
                          to fulfill an obligation under the Act, a special
                          regulation or an international agreement by which the
                          applicable law is bound, or to fulfill a task
                          performed in the public interest or in the exercise of
                          public power entrusted to the Company,
                        </li>
                        <li>
                          for reasons of public interest in the field of public
                          health, for archiving purposes in the public interest,
                          for scientific or historical research or for
                          statistical purposes, where the right of erasure is
                          likely to prevent or seriously impede the attainment
                          of the objectives of such processing; or
                        </li>
                        <li>to establish, assert or defend legal claims.</li>
                        <li>
                          during the period allowing the Company to verify the
                          accuracy of personal data.
                        </li>
                      </ol>
                      <small>
                        The Company will delete the personal data of the data
                        subjects upon request, without undue delay, after
                        evaluating that the data subject's request is justified.
                      </small>
                      <small>
                        The data subject has the right to restrict the
                        processing of personal data if:
                      </small>
                      <ol>
                        <li>
                          the processing is illegal and the data subject
                          requests that the use of personal data be restricted
                          instead of deleted;
                        </li>
                        <li>
                          The company no longer needs personal data for
                          processing purposes, but the data subject needs them
                          to prove, assert or defend legal claims;
                        </li>
                        <li>
                          the data subject objected to the processing of
                          personal data on the basis of a legitimate claim of
                          the Company, until it is verified whether the
                          legitimate reasons on the part of the Company outweigh
                          the legitimate reasons of the data subject.
                        </li>

                        <li>
                          If the data subject requests a restriction on the
                          processing of his or her personal data, the Company
                          will not carry out any processing operations with the
                          data subject other than the storage or use of legal
                          claims without the data subject's consent.
                          <ol>
                            <li>
                              In the event that the restriction on the
                              processing of personal data is to be lifted, the
                              Company will inform the data subject in advance.
                            </li>
                            <li>
                              The data subject has the right to data transfer,
                              which means obtaining personal data provided by
                              the Company, while having the right to transfer
                              this data to another operator in a normally usable
                              and machine-readable format, provided that the
                              personal data were obtained with the data
                              subject's consent or contracts and their
                              processing takes place in the form of automated
                              means.
                            </li>
                            <li>
                              The data subject has the right to object at any
                              time to the processing of his or her personal data
                              on grounds relating to his or her specific
                              situation. The data subject may object to the
                              processing of his or her personal data on the
                              basis of:
                            </li>
                          </ol>
                        </li>
                        <li>
                          the legal title of performing tasks performed in the
                          public interest or in the exercise of public power, or
                          from the legal title of the legitimate interest of the
                          Company,
                        </li>
                        <li>
                          processing of personal data for direct marketing
                          purposes,
                        </li>
                        <li>
                          processing for scientific or historical research or
                          statistical purposes.
                        </li>
                      </ol>
                      <small>
                        If the data subject objects to the processing of
                        personal data for the purposes of direct marketing
                        pursuant to point b) of this paragraph, the Company may
                        not further process his personal data.
                      </small>
                      <small>
                        The company will assess the objection received in due
                        course. The Company may not further process personal
                        data unless it demonstrates the necessary legitimate
                        interests for the processing of personal data which
                        outweigh the rights or interests of the data subject or
                        the grounds for a legal claim.
                      </small>
                      <small>
                        The data subject has the right to object to the
                        Company's decision at any time, which has legal effects
                        affecting the data subject or significantly affecting
                        him, if such a decision is issued solely on the basis of
                        acts of automated processing of his personal data. The
                        affected person has the right to request the Company to
                        review the issued decision by a method different from
                        the automated form of processing, while the Company is
                        obliged to comply with the requested person's request in
                        a non-automated manner, ie the decision is verified by
                        the authorized person personally. The buyer does not
                        have this right only if:
                      </small>
                      <ul>
                        <li>
                          the right to access their data. At the request of the
                          data subject, the Operator shall issue a confirmation
                          as to whether the personal data of the data subject
                          concerning him or her are being processed. If the
                          Operator processes this data, it shall issue a copy of
                          this personal data of the data subject upon request.
                          In addition to the above, the data subject will be
                          provided with information on the purpose of this
                          processing, the category of personal data processed,
                          the identification of the recipient or categories of
                          recipients to whom the personal data were provided,
                          the retention period, personal data, information on
                          the existence and automated individual
                          decision-making, including profiling. Issuing the
                          first copy according to the previous sentence is free
                          of charge. For any additional copies requested by the
                          person concerned, the Operator will charge a fee
                          corresponding to the administrative costs incurred by
                          him in connection with the issuance of the copy. If a
                          person requests information by electronic means, it
                          will be provided to him in a commonly used electronic
                          form, by e-mail, unless he requests otherwise.
                          incurred in connection with the issuance of the copy.
                          If a person requests information by electronic means,
                          it will be provided to him in a commonly used
                          electronic form, by e-mail, unless he requests
                          otherwise. incurred in connection with the issuance of
                          the copy. If a person requests information by
                          electronic means, it will be provided to him in a
                          commonly used electronic form, by e-mail, unless he
                          requests otherwise.
                        </li>
                        <li>
                          the right to correct personal data if the Operator
                          registers incorrect personal data about the person
                          concerned. At the same time, the data subject has the
                          right to complete incomplete personal data. The
                          operator shall correct or supplement the personal data
                          without undue delay after the person concerned so
                          requests.
                        </li>
                        <li>
                          the right to erase personal data concerning the data
                          subject, provided that:
                          <ol>
                            <li>
                              the personal data are no longer necessary for the
                              purposes for which they were obtained or otherwise
                              processed;
                            </li>
                            <li>
                              the person concerned withdraws the consent on the
                              basis of which the processing is carried out;
                            </li>
                            <li>
                              the data subject objects to the processing of
                              personal data,
                            </li>
                            <li>
                              personal data have been processed illegally,
                            </li>
                            <li>
                              the reason for deletion is the fulfillment of an
                              obligation under the Act, special regulation or
                              international agreement by which the applicable
                              law is bound, or
                            </li>
                            <li>
                              personal data were obtained in with the offer of
                              information society services to a person under 16
                              years of age.
                            </li>
                          </ol>
                        </li>
                      </ul>
                      <small>
                        The data subject will not have the right to have
                        personal data erased provided that their processing is
                        necessary:
                      </small>
                      <ol>
                        <li>
                          to exercise the right to freedom of expression and
                          information;
                        </li>
                        <li>
                          to fulfill the obligation under the Act, special
                          regulation or international agreement by which the
                          applicable law is bound, or to fulfill the task
                          performed in the public interest or in the exercise of
                          public power entrusted to the Operator,
                        </li>
                        <li>
                          for reasons of public interest in the field of public
                          health, for archiving purposes in the public interest,
                          for scientific or historical research purposes or for
                          statistical purposes, where the right of erasure is
                          likely to make it impossible or seriously difficult to
                          achieve the objectives of such processing, or
                        </li>
                        <li>
                          to prove, assert or defend legal claims. The
                          controller shall delete the personal data of the data
                          subjects upon request, without undue delay, after
                          assessing that the data subject's request is
                          justified.
                        </li>
                        <li>
                          The data subject has the right to limit the processing
                          of personal data if:
                          <ol>
                            <li>
                              he or she objects to the accuracy of the personal
                              data during a period allowing the Operator to
                              verify the accuracy of the personal data;
                            </li>
                            <li>
                              the processing is illegal and the data subject
                              requests that the use of personal data be
                              restricted instead of deleted;
                            </li>
                            <li>
                              The controller no longer needs personal data for
                              processing purposes, but the data subject needs
                              them to prove, assert or defend legal claims;
                            </li>
                            <li>
                              the data subject has objected to the processing of
                              personal data on the basis of the Operator's
                              legitimate claim, until it is verified whether the
                              legitimate reasons on the part of the Operator
                              outweigh the legitimate reasons of the data
                              subject.
                            </li>
                          </ol>
                        </li>
                      </ol>
                      <small>
                        If the data subject requests a restriction on the
                        processing of his / her personal data, the Operator will
                        not carry out any processing operations with the data
                        subject, except for the storage or use of legal claims.
                      </small>
                      <small>
                        In the event that the restriction on the processing of
                        personal data is to be lifted, the Operator shall inform
                        the data subject in advance.
                      </small>
                      <ul>
                        <li>
                          The data subject has the right to data transfer, which
                          means obtaining personal data provided to the
                          Operator, while having the right to transfer this data
                          to another operator in a normally usable and
                          machine-readable format, provided that the personal
                          data were obtained with the data subject's consent or
                          contracts and their processing takes place in the form
                          of automated means.
                        </li>
                        <li>
                          The right to withdraw your consent to the processing
                          of personal data at any time. If the data subject is
                          under 16 years of age, such processing of personal
                          data is lawful only under the conditions and to the
                          extent that such consent has been given or approved by
                          his or her legal representative.
                        </li>
                        <li>
                          The data subject has the right to file a motion to
                          initiate proceedings with the Office for Personal Data
                          Protection of the applicable law if he or she
                          considers that his or her personal data protection
                          rights have been violated.
                        </li>
                      </ul>
                      <small>
                        In addition to the operator, third parties may have
                        access to the personal data you provide. Third parties
                        may include accounting service providers, IT service
                        providers, newsletter service providers, cookie
                        companies, as well as persons authorized by the operator
                        to process personal data for the purpose of providing
                        marketing services.
                      </small>
                      <br />
                      <small>Privacy Policy last update:</small>{" "}
                      <time dateTime="2021-12-31">December 31, 2021.</time>
                    </dd>
                    <dt
                      id="terms-of-use"
                      className={`${termStyles} ${technoOrange} ${term3Styles}`}
                      onClick={() => setActiveLegalTab("terms-of-use")}
                    >
                      Terms of Use
                    </dt>
                    <dd
                      className={
                        activeLegalTab === "terms-of-use"
                          ? "active-l-tab"
                          : "inactive-l-tab"
                      }
                    >
                      <small>
                        Thank you for accessing the website (“Website”) of Klove
                        &amp; Sitch Agency Limited and its affiliates. By using
                        or accessing this Website, you hereby accept and agree
                        to comply with the following terms and conditions
                        (“Terms of Use”). These Terms of Use are a binding
                        agreement between you and Klove &amp; Sitch Agency
                        Limited (“The Company”), which will govern your access
                        to this Website, including any information, data, tools,
                        products, services and other content (“Content”)
                        available on or through this Website.
                      </small>
                      <small>
                        Each time you use the Website you accept and agree to
                        abide by these Terms of Use in its current form and
                        accordingly with the last updated version. Do not use
                        the Website if you do not accept the Terms of Use stated
                        herein. The breach of any provision of these Terms of
                        Use may revoke and terminate your right to access the
                        Website, notwithstanding you may be fully liable for
                        conversion, misappropriation, and all other claims
                        arising from or relating to your continued use of the
                        Website after such breach.
                      </small>
                      <h4>USE OF THE WEBSITE</h4>
                      <small>
                        The Company grants a limited right to use the Website,
                        which is subject to your full agreement to abide by
                        these Terms of Use, as well as any other rules,
                        procedures, policies, terms or conditions that govern
                        all or any portion of the Website. At any time and at
                        The Company’s sole discretion, your right to use all or
                        portion of the Website may be revoked.
                      </small>
                      <small>
                        You may not take any action that imposes an unreasonable
                        or disproportionately large load on the infrastructure
                        of the Website or The Company’s systems or networks, or
                        any systems or networks connected to the Website or to
                        The Company.
                      </small>
                      <small>
                        This Website is owned by The Company and is protected by
                        copyrights, patents, database rights, trademarks,
                        servicemarks and/or other intellectual property and
                        proprietary rights that are owned by The Company. You
                        may not decompose, decompile, reverse engineer,
                        disassemble or otherwise deconstruct all or any portion
                        of the Website. You may not publish, broadcast,
                        retransmit, reproduce, repackage, frame, commercially
                        exploit, create any derivative of or otherwise
                        redistribute all or any portion of the Website, except
                        as explicitly permitted in these Terms of Use or by The
                        Company, by written consent.
                      </small>
                      <h4>UPDATES TO THE WEBSITE</h4>
                      <small>
                        The Company reserves the right, at its sole discretion,
                        to change, modify, add or remove any part of the
                        Website, the Content and these Terms of Use at any time
                        and without prior notice. For this reason, each time you
                        use the Website, you should visit and review the
                        then-current Terms of Use that are applicable. Following
                        the posting of changes to the Terms of Use, your
                        continued use of the Website shall be considered as an
                        acceptance and agreement to the changes implemented. The
                        Company undertakes no obligation to update publicly or
                        revise any information on the Website, whether as a
                        result of new information, future developments or
                        otherwise.
                      </small>
                      <h4>WEBSITE SECURITY</h4>
                      <small>
                        The Company prohibits any user from violating or
                        attempting to violate the security of the Website,
                        including, without limitation, (a) accessing data not
                        intended for the respective user or logging onto a
                        server or an account which the user is not authorized to
                        access; (b) disabling, removing, defeating, or avoiding
                        any security device or system, including, without
                        limitation, any password and login functionality used to
                        authenticate users; (c) attempting to probe, scan or
                        test the vulnerability of a system or network or to
                        breach security or authentication measures without
                        proper authorization; (d) attempting to interfere with
                        service to any user, host or network; (e) sending
                        unsolicited email, including promotions and/or
                        advertising of products or services; (f) using or
                        attempting to use any engine, software, tool, agent or
                        other device or mechanism (including, without
                        limitation, browsers, spiders, robots, avatars or
                        intelligent agents) to navigate or search the Website
                        other than the search engine and search agents available
                        on the Website; (g) reverse engineering, decompiling or
                        disassembling the underlying software; (h) removing any
                        notices, warnings, labels, annotations or instructions
                        from any portion of the Website or any related material,
                        including, without limitation, any patent, trademark,
                        copyright, or other proprietary notices or license
                        provisions; or (i) invading the privacy of, obtaining
                        the identity of, or obtaining any personal information
                        about any other user of the Website.
                      </small>
                      <h4>PASSWORD PROTECTED AREAS</h4>
                      <small>
                        Certain Contents may be made available through a
                        password protected area on the Website, which is
                        intended to be confidential and used only by the
                        individual or entity to whom it was issued. The users
                        are not allowed to disclose your password to any
                        unauthorized person, and the users are solely
                        responsible for maintaining the confidentiality and
                        security of its password.
                      </small>
                      <small>
                        If you access Content through a password protected area,
                        please note that the written agreements between you and
                        the applicable Klove & Sitch Agency entities, as well as
                        any provisions set forth in those Contents, govern the
                        parties’ respective rights and obligations with regard
                        to those Contents.
                      </small>
                      <small>
                        Contents provided through a password protected area
                        generally are confidential and subject to limitations on
                        disclosure and use, and The Company’s individuals and
                        entities generally have limited liability with respect
                        to the accuracy and completeness of such Contents.
                      </small>
                      <small>
                        In case of actual or suspected loss, theft or
                        unauthorized use of password, The Company should be
                        notified immediately. The Company is not obligated to
                        inquire as to the authority or propriety of any use of
                        or action taken under your password, and will not be
                        responsible for any loss that arises from such use or
                        action, or from the users failure to comply with these
                        Terms of Use.
                      </small>
                      <h4>INTELLECTUAL PROPERTY</h4>
                      <small>
                        The users may download or copy Content only to the
                        extent such download is expressly permitted in writing
                        on the Website. No right, title or interest in any
                        downloaded materials or software is transferred to the
                        user as a result of any such downloading or copying. The
                        user may not reproduce (except as noted above), publish,
                        transmit, distribute, display, modify, sell or
                        participate in any sale of or exploit in any way, in
                        whole or in part, any of the Content, the Website or any
                        related software.
                      </small>
                      <small>
                        Nothing on the Website shall be interpreted as granting
                        any license or right to use any image, trademark, trade
                        dress, logo or service mark on the Website. The Company
                        reserves all rights with respect to copyright and
                        trademark ownership of all material on the Website and
                        will enforce such rights to the full extent of the law.
                        All trademarks and logos displayed on this website are
                        the property of The Company.
                      </small>
                      <h4>NO OFFERS OR RELIANCE</h4>
                      <small>
                        All Content available on the Website is provided for
                        informational purposes only.
                      </small>
                      <small>
                        In addition, no information, Content or other materials
                        contained on the Website should be construed or relied
                        upon as professional advice.
                      </small>
                      <h4>LIMITATIONS OF LIABILITY</h4>
                      <small>
                        The Company is not responsible for any damages or
                        injury, including but not limited to special or
                        consequential damages, that result from the use of (or
                        inability to use) this Website, including any damages or
                        injury caused by any failure of performance, error,
                        omission, interruption, deletion, defect, delay in
                        operation or transmission, computer virus, communication
                        line failure, theft, destruction or unauthorized access
                        to, or other computer malfunction. You acknowledge that
                        The Company provides the Contents on an “as is” and “as
                        available” basis with no warranties of any kind. Your
                        use of this Website and use or reliance upon any of the
                        materials is made solely at your own risk.
                      </small>
                      <small>
                        Any Content downloaded or otherwise obtained through the
                        Website is done at the users own discretion and risk and
                        the user is solely responsible for any damage to his
                        computer or other electronic system or loss of data that
                        results from the download of any such Content. The
                        foregoing limitation of liability will apply in any
                        action, whether in contract, tort or any other claim,
                        even if an authorized representative of The Company has
                        been advised of or should have knowledge of the
                        possibility of such damages.
                      </small>
                      <small>
                        Without limiting our general disclaimer, The Company
                        does not warrant the availability, accuracy,
                        completeness, timeliness, functionality, reliability,
                        sequencing, or speed of delivery of the site or any part
                        of the content.
                      </small>
                      <h4>RIGHT TO MONITOR</h4>
                      <small>
                        The Company has the right but not the obligation to
                        monitor and record activity on the Website and respond
                        as it deems appropriate. The Company may monitor and
                        record activity on the Website for analytics purposes
                        and may investigate any complaint or reported violation
                        of The Company’s policies.
                      </small>
                      <small>
                        The Company may report any activity that it suspects may
                        violate any law or regulation to regulators, law
                        enforcement officials of other persons or entities that
                        it deems appropriate. The Company may also issue
                        warning, suspend or terminate use of the Website, deny
                        access to all or part of the Website or take any other
                        action that it deems appropriate.
                      </small>
                      <h4>THIRD PARTY CONTENT</h4>
                      <small>
                        References on this Website to any names, marks, products
                        or services of third parties, or hypertext links to
                        third party sites or information or Content provided by
                        third parties, are provided solely as a convenience to
                        the users and do not constitute or imply The Company’s
                        endorsement, sponsorship, affiliation or recommendation
                        of the third party, its information, materials or
                        services.
                      </small>
                      <h4>SUBMITTING MATERIALS OR CONFIDENTIAL INFORMATION</h4>
                      <small>
                        The Company receives a large number of business ideas
                        and materials that we analyze, and in the absence of our
                        express written agreement to do so, we cannot accept the
                        responsibility for protecting those against misuse or
                        disclosure of any confidential or proprietary
                        information or other materials. Any plans, information
                        and other materials you submit in connection with this
                        Website may be used or disclosed by us for any purpose
                        and in any manner, as we determine in our sole
                        discretion. Please consider this carefully before
                        sending us any information or other materials that you
                        deem confidential or proprietary.
                      </small>
                      <h4>TERMINATION OF USE</h4>
                      <small>
                        These Terms of Use are effective unless and until
                        terminated by either the user or The Company. The user
                        may terminate these Terms of Use at any time by
                        discontinuing to use the Website. The Company may
                        terminate these Terms of Use, at its sole discretion and
                        at any time, and may do so immediately without notice by
                        denying the user access to the Website. Upon any
                        termination of these Terms of Use by either The Company
                        or the user, the respective user must promptly destroy
                        all materials downloaded or otherwise obtained from the
                        Website, as well as all copies of such materials,
                        whether made under these Terms of Use or otherwise.
                      </small>
                      <h4>INDEMNITIES</h4>
                      <small>
                        As a condition of the usage of the Website, the user
                        agree to indemnify and hold harmless The Company and its
                        agents, employees and third party sources from and
                        against any and all suits, claims, losses, demands,
                        liabilities, damages, costs and expenses (including
                        attorney’s fees), as incurred, arising from: (i) the use
                        of the Website or related services; (ii) any violation
                        of applicable law, statute, ordinance, regulation or any
                        third party’s rights; (iii) any violation of these Terms
                        of Use; (iv) claims asserted by third parties which, if
                        proven, would place you in breach of representations,
                        warranties, covenants or other provisions contained in
                        these Terms of Use.
                      </small>
                      <h4>REPRESENTATIONS AND WARRANTIES</h4>
                      <small>
                        You represent and warrant that: (i) you have full
                        authority and all rights necessary to enter into and
                        fully perform all of your obligations pursuant to these
                        Terms of Use; (ii) you have not and you will not enter
                        into any agreement or perform any act which might
                        contravene the purposes and/or effects of these Terms of
                        Use; and (iii) you will not delete any Content.
                      </small>
                      <small>EFFECT ON OTHER AGREEMENTS</small>
                      <small>
                        Nothing contained in these Terms of Use is intended to
                        modify or amend any other written agreement you may have
                        with The Company (including, without limitation, any
                        customer agreement, participation agreement or account
                        agreement) (collectively, “Other Agreements”), if any,
                        that may currently be in effect. In the event of any
                        inconsistency between these Terms of Use and any Other
                        Agreement, the Other Agreement will govern.
                      </small>
                      <h4>GENERAL CONDITIONS</h4>
                      <small>
                        The Company may assign these Terms of Use in whole or in
                        part at any time without the users’ consent. The user
                        may not assign these Terms of Use or delegate any of his
                        obligations under these Terms of Use. Any purported
                        assignment of these Terms of Use in violation of its
                        terms is void.
                      </small>
                      <small>
                        If any provision of these Terms of Use is found invalid
                        or unenforceable, that provision shall be enforced to
                        the maximum extent possible and the remaining provisions
                        of these Terms of Use shall remain in full force and
                        effect.
                      </small>
                      <h4>GOVERNING LAW</h4>
                      <small>
                        This Website, this Privacy Policy and this Terms of Use,
                        as well as any disputes relating to any of them, shall
                        be governed to the extent permitted by the applicable
                        law.
                      </small>
                      <h4>CONTACT INFORMATION</h4>
                      <small>
                        Any questions, concerns or complaints regarding these
                        Terms of Use should be sent to
                        letsbuild@kloveandsitch.com.
                      </small>
                      <br />
                      <small>Terms of Use last update:</small>{" "}
                      <time dateTime="2021-12-31">December 31, 2021.</time>
                    </dd>
                  </dl>
                </div>
              </section>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <SEO title="Legal Compliance" />
              <Helmet>
                <title>Legal Compliance</title>
              </Helmet>
              <Container>
                <Layout>
                  <MainContent>
                    <Branding getStandAloneTimelines={getStandAloneTimelines} />
                    <Header title="Legal" highlighted="Compliance">
                      <p style={startOverText}>
                        Looks like you closed this page.
                      </p>
                      <Link
                        style={startOverLink}
                        className={technoOrange}
                        to="/"
                      >
                        Go back to the homepage
                      </Link>
                    </Header>
                  </MainContent>
                </Layout>
              </Container>
            </React.Fragment>
          )}
        </div>
      )}
    </ModalRoutingContext.Consumer>
  );
}

export default CompliancePage;

import React from "react";
import {
  h1Styles,
  technoOrange,
  matrixBlack,
} from "../styles/global.module.css";
import {
  headerStyles,
  headerTextBgStyles,
  headerAestheticStyles,
} from "../styles/header.module.css";

function Header({ children, title, highlighted }) {
  return (
    <React.Fragment>
      <header className={headerStyles}>
        <div className={`aesthetics ${technoOrange} ${headerAestheticStyles}`}>
          <svg
            width="122"
            height="150"
            viewBox="0 0 122 150"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="aesthetics-slash">
              <g id="slashes">
                <g id="1">
                  <path
                    d="M4.42554 112.64L44.5269 36.6357H52.1343L11.9402 112.64H4.42554Z"
                    fill="#FC4D0D"
                  />
                  <path
                    d="M4.42554 112.64L2.21443 111.474L0.279859 115.14H4.42554V112.64ZM44.5269 36.6357V34.1357H43.0193L42.3157 35.4691L44.5269 36.6357ZM52.1343 36.6357L54.3443 37.8045L56.2844 34.1357H52.1343V36.6357ZM11.9402 112.64V115.14H13.4462L14.1502 113.809L11.9402 112.64ZM6.63665 113.807L46.738 37.8024L42.3157 35.4691L2.21443 111.474L6.63665 113.807ZM44.5269 39.1357H52.1343V34.1357H44.5269V39.1357ZM49.9243 35.467L9.73019 111.472L14.1502 113.809L54.3443 37.8045L49.9243 35.467ZM11.9402 110.14H4.42554V115.14H11.9402V110.14Z"
                    fill="black"
                  />
                </g>
                <g id="2">
                  <path
                    d="M27.4255 112.64L67.5269 36.6357H75.1343L34.9402 112.64H27.4255Z"
                    fill="#FC4D0D"
                  />
                  <path
                    d="M27.4255 112.64L25.2144 111.474L23.2799 115.14H27.4255V112.64ZM67.5269 36.6357V34.1357H66.0193L65.3157 35.4691L67.5269 36.6357ZM75.1343 36.6357L77.3443 37.8045L79.2844 34.1357H75.1343V36.6357ZM34.9402 112.64V115.14H36.4462L37.1502 113.809L34.9402 112.64ZM29.6366 113.807L69.738 37.8024L65.3157 35.4691L25.2144 111.474L29.6366 113.807ZM67.5269 39.1357H75.1343V34.1357H67.5269V39.1357ZM72.9243 35.467L32.7302 111.472L37.1502 113.809L77.3443 37.8045L72.9243 35.467ZM34.9402 110.14H27.4255V115.14H34.9402V110.14Z"
                    fill="black"
                  />
                </g>
                <g id="3">
                  <path
                    d="M50.4255 112.64L90.5269 36.6357H98.1343L57.9402 112.64H50.4255Z"
                    fill="#FC4D0D"
                  />
                  <path
                    d="M50.4255 112.64L48.2144 111.474L46.2799 115.14H50.4255V112.64ZM90.5269 36.6357V34.1357H89.0193L88.3157 35.4691L90.5269 36.6357ZM98.1343 36.6357L100.344 37.8045L102.284 34.1357H98.1343V36.6357ZM57.9402 112.64V115.14H59.4462L60.1502 113.809L57.9402 112.64ZM52.6366 113.807L92.738 37.8024L88.3157 35.4691L48.2144 111.474L52.6366 113.807ZM90.5269 39.1357H98.1343V34.1357H90.5269V39.1357ZM95.9243 35.467L55.7302 111.472L60.1502 113.809L100.344 37.8045L95.9243 35.467ZM57.9402 110.14H50.4255V115.14H57.9402V110.14Z"
                    fill="black"
                  />
                </g>
              </g>
            </g>
          </svg>
        </div>
        <h1 className={h1Styles}>
          {title}{" "}
          <span
            className={`${technoOrange} ${headerTextBgStyles} ${matrixBlack}`}
          >
            {highlighted}
          </span>
        </h1>
        {children}
      </header>
    </React.Fragment>
  );
}

export default Header;
